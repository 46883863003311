




































import { City } from "@/modules/dishes/@types/city.type";
import { DataTableHeader } from "vuetify/types";
import { Component, Prop, Vue } from "vue-property-decorator";

import EditCities from "./edit/EditCity.vue";
import ConfirmDialog from "@/components/ui/dialogs/ConfirmDialog.vue";

import { ProductsManagementService } from "../api/products-management.service";

@Component({ components: { EditCities, ConfirmDialog } })
export default class CitiesManagement extends Vue {
  @Prop()
  private cities: City[];

  $refs: {
    editCitiesRef: EditCities;
    deleteDialogRef: ConfirmDialog;
  };

  isEditOrderMode = false;
  isNew = false;

  get headers(): DataTableHeader[] {
    const headers = [{ text: "Город", value: "name" }];
    if (!this.isEditOrderMode) headers.push({ text: "Стоимость", value: "delivery_price" });
    headers.push({ text: "Действия", value: "actions" });
    return headers;
  }

  showEditDialog(city: City) {
    this.isNew = false;
    this.$refs.editCitiesRef.show(city);
  }

  showAddDialog() {
    this.isNew = true;
    this.$refs.editCitiesRef.show();
  }

  async showDeleteDialog(city: City) {
    const isDeleted = await this.$refs.deleteDialogRef.show();
    if (!isDeleted) return;
    await ProductsManagementService.deleteCity(city.id);
    this.$emit("onCitiesUpdated");
  }

  async onUpdate(payload) {
    await ProductsManagementService.updateCity(payload);
    this.$emit("onCitiesUpdated");
  }

  onOrderMovedToTheTop(group: City) {
    if (group.city_sort_number! <= 1) return;

    const swapableGroup = this.cities.find(iterable => iterable.city_sort_number === group.city_sort_number! - 1);
    if (swapableGroup && swapableGroup.city_sort_number) swapableGroup.city_sort_number = group.city_sort_number;
    if (group.city_sort_number) group.city_sort_number--;
  }

  onOrderMovedToTheBottom(group: City) {
    const swapableGroup = this.cities.find(iterable => iterable.city_sort_number === group.city_sort_number! + 1);
    if (swapableGroup && swapableGroup.city_sort_number) swapableGroup.city_sort_number = group.city_sort_number;
    if (group.city_sort_number) group.city_sort_number++;
  }

  get citiesSorted() {
    return this.cities.sort(({ city_sort_number: a }, { city_sort_number: b }) => (a as number) - (b as number));
  }

  async onSaveCitiesOrder() {
    await ProductsManagementService.updateCitiesOrder(this.cities);
    this.isEditOrderMode = false;
  }
}
