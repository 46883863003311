










































import { Category } from "@/modules/dishes/@types/product.type";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import EditGroups from "./edit/EditGroups.vue";
import AddGroups from "./edit/EditGroups.vue";
import { ProductsManagementService } from "../api/products-management.service";
import ConfirmDialog from "@/components/ui/dialogs/ConfirmDialog.vue";

@Component({ components: { EditGroups, AddGroups, ConfirmDialog } })
export default class GroupsManagement extends Vue {
  @Prop()
  readonly groups: Category[];

  isEditOrderMode = false;

  $refs: {
    editGroupsRef: EditGroups;
    addGroupsRef: AddGroups;
    deleteDialogRef: ConfirmDialog;
  };

  get headers(): DataTableHeader[] {
    return [
      { text: "Группа", value: "name" },
      { text: "Действия", value: "actions" },
    ];
  }

  showEditDialog(item) {
    this.$refs.editGroupsRef.show(item);
  }

  showAddDialog() {
    this.$refs.addGroupsRef.show();
  }

  async showDeleteDialog(item: Category) {
    const isConfirmed = await this.$refs.deleteDialogRef.show();
    if (!isConfirmed) return;
    await ProductsManagementService.deleteGroup(item.category_id);
    this.$emit("onGroupsUpdated");
  }

  async onGroupEdited(payload: Category) {
    await ProductsManagementService.updateGroup(payload);
    this.$emit("onGroupsUpdated");
  }

  async onGroupAdded(name: string) {
    await ProductsManagementService.addGroup(name);
    this.$emit("onGroupsUpdated");
  }

  get sortedArray() {
    return this.groups.sort(({ group_sort_number: a }, { group_sort_number: b }) => (a as number) - (b as number));
  }

  onOrderMovedToTheTop(group: Category) {
    if (group.group_sort_number! <= 1) return;

    const swapableGroup = this.groups.find(iterable => iterable.group_sort_number === group.group_sort_number! - 1);
    if (swapableGroup && swapableGroup.group_sort_number) swapableGroup.group_sort_number = group.group_sort_number;
    if (group.group_sort_number) group.group_sort_number--;
  }

  onOrderMovedToTheBottom(group: Category) {
    const swapableGroup = this.groups.find(iterable => iterable.group_sort_number === group.group_sort_number! + 1);
    if (swapableGroup && swapableGroup.group_sort_number) swapableGroup.group_sort_number = group.group_sort_number;
    if (group.group_sort_number) group.group_sort_number++;
  }

  async onSaveGroupsOrder() {
    await ProductsManagementService.updateGroupsOrder(this.groups);
    this.isEditOrderMode = false;
  }
}
