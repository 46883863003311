















import { Component, Prop } from "vue-property-decorator";
import { Category } from "@/modules/dishes/@types/product.type";
import FormValidator from "@/mixins/FormValidator";

@Component
export default class EditGroups extends FormValidator {
  @Prop({ type: Boolean, default: true })
  private isNew: boolean;

  formRef = "formRef";

  isVisible = false;

  item: Nullable<Category> = null;
  name: Nullable<string> = null;

  show(item?: Category) {
    this.isVisible = true;
    this.name = item?.name as string;
    this.item = item as Category;
  }

  onConfirm() {
    if (!this.validateForm()) return;
    if (this.isNew) {
      this.$emit("onGroupAdded", this.name);
    } else this.$emit("onGroupEdited", { category_id: this.item?.category_id, name: this.name });

    this.onClose();
  }

  async onClose() {
    await this.resetForm();
    this.name = null;
    this.isVisible = false;
  }
}
