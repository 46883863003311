
















































import { Product } from "@/modules/dishes/@types/product.type";
import { DataTableHeader } from "vuetify/types";
import { Component, Prop, Vue } from "vue-property-decorator";

import ConfirmDialog from "@/components/ui/dialogs/ConfirmDialog.vue";
import { ProductsManagementService } from "../api/products-management.service";

@Component({ components: { ConfirmDialog } })
export default class ProductsManagement extends Vue {
  @Prop()
  private products: Product[];

  selectedProduct: Nullable<Product> = null;
  isEditOrderMode = false;

  $refs: {
    deleteDialogRef: ConfirmDialog;
  };

  get headers(): DataTableHeader[] {
    return [
      // { text: "Группа", value: "category_name" },
      { text: "Название", value: "product_name" },
      { text: "Действия", value: "actions" },
    ];
  }

  onClickOnRow(item: Product) {
    this.$router.push({ name: "EditProduct", query: { productId: item.id.toString() } });
  }

  onAddNewItem() {
    this.$router.push({ name: "EditProduct", query: { isNew: String(true) } });
  }

  async showDeleteDialog(item: Product) {
    this.selectedProduct = item;
    const isDeleted = await this.$refs.deleteDialogRef.show();
    this.selectedProduct = null;
    if (!isDeleted) return;
    await ProductsManagementService.deleteProduct(item.id);
    this.$emit("onProductsUpdated");
  }

  onOrderMovedToTheTop(group: Product) {
    if (group.price_sort_number! <= 1) return;

    const swapableGroup = this.products.find(iterable => iterable.price_sort_number === group.price_sort_number! - 1);
    if (swapableGroup && swapableGroup.price_sort_number) swapableGroup.price_sort_number = group.price_sort_number;
    if (group.price_sort_number) group.price_sort_number--;
  }

  onOrderMovedToTheBottom(group: Product) {
    const swapableGroup = this.products.find(iterable => iterable.price_sort_number === group.price_sort_number! + 1);
    if (swapableGroup && swapableGroup.price_sort_number) swapableGroup.price_sort_number = group.price_sort_number;
    if (group.price_sort_number) group.price_sort_number++;
  }

  get sortedArray() {
    return this.products.sort(({ price_sort_number: a }, { price_sort_number: b }) => (a as number) - (b as number));
  }

  async onSaveProductsOrder() {
    await ProductsManagementService.updateProductsOrder(this.products);
    this.isEditOrderMode = false;
  }
}
